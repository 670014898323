var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-container" }, [
    _c("img", {
      staticStyle: {
        width: "150px",
        position: "absolute",
        top: "20px",
        left: "20px",
      },
      attrs: { src: require("@/assets/login/logo-white.png"), alt: "" },
    }),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("img", {
          staticClass: "leftImg",
          attrs: { src: require("@/assets/login/left.png"), alt: "" },
        }),
        _c(
          "el-form",
          {
            ref: "loginForm",
            staticClass: "login-form",
            attrs: {
              autoComplete: "on",
              model: _vm.loginForm,
              rules: _vm.loginRules,
              "label-position": "left",
            },
          },
          [
            _c("h3", { staticClass: "title" }, [_vm._v("量测水远传监控平台")]),
            _c(
              "p",
              { staticClass: "tips", staticStyle: { "margin-top": "5px" } },
              [_vm._v("量测水远传监控平台——单位管理系统")]
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "username" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "username",
                    type: "text",
                    autoComplete: "on",
                    placeholder: "请输入登录账号",
                  },
                  model: {
                    value: _vm.loginForm.username,
                    callback: function ($$v) {
                      _vm.$set(_vm.loginForm, "username", $$v)
                    },
                    expression: "loginForm.username",
                  },
                }),
                _c("span", {
                  staticClass: "icon iconfont icon-yonghu_zhanghao_wode",
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "password" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "password",
                    type: _vm.pwdType,
                    autoComplete: "on",
                    placeholder: "请输入密码",
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleLogin.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.loginForm.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.loginForm, "password", $$v)
                    },
                    expression: "loginForm.password",
                  },
                }),
                _c("span", {
                  class:
                    _vm.pwdType === "password"
                      ? "icon iconfont icon-xianshi_chakan"
                      : "icon iconfont icon-yincang",
                  on: { click: _vm.showPwd },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    staticStyle: {
                      width: "100%",
                      background: "#4452D5",
                      "font-size": "24px",
                      height: "50px",
                    },
                    attrs: { type: "primary", loading: _vm.loading },
                    nativeOn: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.handleLogin.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(" 登 录 ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }